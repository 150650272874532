<!--
 * @Author: zhongtao
 * @LastEditors: zhongtao
-->
<template>
  <div class="cockpit">
    <div class="search-container">
      <div class="btn-wrapper">
        <el-button class="search-button" style="float:right;" @click="getALLData">刷新页面</el-button>
      </div>
    </div>
    <div class="content">
      <!-- 结账数 -->
      <el-card class="box-card-2" style="height: 600px">
        <div slot="header" class="clearfix">
          <div>结账数</div>
          <div>
            <el-date-picker v-model="checkoutDate" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" type="daterange"></el-date-picker>
            <el-button class="search-btn" style="margin-left: 10px;" @click="getCheckoutList">查询</el-button>
          </div>
        </div>
        <div class="contant">
          <p class="title">场馆订单</p>
          <div id="checkout" style="height:409px;"></div>
        </div>
      </el-card>
      <!-- 营业额 -->
      <el-card class="box-card-2" style="height: 600px;margin-right:0">
        <div slot="header" class="clearfix">
          <div>营业额</div>
          <div>
            <el-date-picker v-model="turnoverDate" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" type="daterange"></el-date-picker>
            <el-button class="search-btn" style="margin-left: 10px;" @click="getTurnoveList">查询</el-button>
          </div>
        </div>
        <div class="contant">
          <p class="title">各业务营业额</p>
          <div id="turnover" style="height:330px;"></div>
          <el-table
            :data="turnoverTableData"
            style="width: 100%"
            border>
            <el-table-column
              v-for="(item, index) in turnoverList"
              :key="index"
              :prop="item.props"
              align="center"
              :label="item.label"
              min-width="100">
            </el-table-column>
          </el-table>
        </div>
      </el-card>
      <!-- 售卡数 -->
      <el-card class="box-card-2">
        <div slot="header" class="clearfix">
          <div>售卡数</div>
          <div>
            <el-date-picker v-model="saleDate" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" type="daterange"></el-date-picker>
            <el-button class="search-btn" style="margin-left: 10px;" @click="getSaleList">查询</el-button>
          </div>
        </div>
        <div class="contant">
          <p class="title">会员卡销售数量</p>
          <div id="sale" style="height:300px;"></div>
          <el-table
            :data="saleTableData"
            style="width: 100%"
            border>
            <el-table-column
              v-for="(item, index) in saleList"
              :key="index"
              :prop="item.props"
              align="center"
              :label="item.label"
              min-width="80">
            </el-table-column>
          </el-table>
        </div>
      </el-card>
      <!-- 购卡金额 -->
      <el-card class="box-card-2" style="margin-right:0">
        <div slot="header" class="clearfix">
          <div>购卡金额</div>
          <div>
            <el-date-picker v-model="amountDate" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" type="daterange"></el-date-picker>
            <el-button class="search-btn" style="margin-left: 10px;" @click="getAmountList">查询</el-button>
          </div>
        </div>
        <div class="contant">
          <p class="title">会员卡购买金额</p>
          <div id="amount" style="height:300px;"></div>
          <el-table
            :data="amountTableData"
            style="width: 100%"
            border>
            <el-table-column
              v-for="(item, index) in amountList"
              :key="index"
              :prop="item.props"
              align="center"
              :label="item.label"
              min-width="80">
            </el-table-column>
          </el-table>
        </div>
      </el-card>
      <!-- 场馆收入 -->
      <el-card class="box-card-2">
        <div slot="header" class="clearfix">
          <div>场馆收入</div>
          <div>
            <el-date-picker v-model="incomeDate" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" type="daterange"></el-date-picker>
            <el-button class="search-btn" style="margin-left: 10px;" @click="getIncomeList">查询</el-button>
          </div>
        </div>
        <div class="contant">
          <p class="title">各场馆收入分布</p>
          <div id="income" style="height:300px;"></div>
        </div>
      </el-card>
      <!-- 登记数 -->
      <el-card class="box-card-2" style="margin-right:0">
        <div slot="header" class="clearfix">
          <div>登记数</div>
          <div>
            <el-date-picker v-model="registerDate" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" type="daterange"></el-date-picker>
            <el-button class="search-btn" style="margin-left: 10px;" @click="getRegisterList">查询</el-button>
          </div>
        </div>
        <div class="contant">
          <p class="title">登记总人数</p>
          <div id="register" style="height:300px;"></div>
        </div>
      </el-card>
      <!-- 客流量分布 -->
      <el-card class="box-card-2 box-card-1">
        <div slot="header" class="clearfix">
          <div>客流量分布</div>
          <div class="serchBox">
            <el-date-picker v-model="queryDate" value-format="yyyy-MM-dd" type="date" placeholder="请选择日期"></el-date-picker>
            <el-button class="search-btn" style="margin-left: 10px;" @click="getPassengerFlowList">查询</el-button>
          </div>
        </div>
        <div class="contant">
          <p class="title">每小时客流量分布</p>
          <div id="passengerFlow" style="height:300px;"></div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
// import mixin from '@/mixins/index'
import echarts from "echarts";
import apis from '@/apis'

export default {
  // mixins: [mixin],
  data() {
    return {
      checkoutDate: [], // 场馆订单筛选日期
      turnoverDate: [], // 各业务营业额筛选日期
      saleDate: [], // 售卡数筛选日期
      amountDate: [], // 会员卡购买金额筛选日期
      incomeDate: [], // 场馆收入分布筛选日期
      registerDate: [], // 登记总人数筛选日期
      queryDate: '', // 客流量分布筛选日期
      turnoverList: [], // 营业额的表格
      turnoverTableData: [], // 营业额表格数据
      saleList: [], // 会员卡销售数量的表格
      saleTableData: [], // 会员卡销售数量的表格数据
      amountList: [], // 购买金额
      amountTableData: [], // 购买金额表格数据
    }
  },
  mounted() {
    this.getCheckoutList() // 结账
    this.getTurnoveList() // 营业额
    this.getSaleList() // 售卡数
    this.getAmountList() // 购买金额
    this.getIncomeList() // 收入
    this.getRegisterList() // 登记
    this.getPassengerFlowList() // 客流分布
  },
  methods: {
    // 刷新页面
    getALLData() {
      this.getCheckoutList() // 结账
      this.getTurnoveList() // 营业额
      this.getSaleList() // 售卡数
      this.getAmountList() // 购买金额
      this.getIncomeList() // 收入
      this.getRegisterList() // 登记
      this.getPassengerFlowList() // 客流分布
    },
    // 接口获取场馆订单数据
    getCheckoutList() {
      let beginTime = ''
      let endTime = ''
      if (this.checkoutDate && this.checkoutDate.length > 0) {
        beginTime = this.checkoutDate[0]
        endTime = this.checkoutDate[1]
      }
      this.$http.get(`${apis.venueOrderCount}?beginTime=${beginTime}&endTime=${endTime}`).then((res) => {
        if (res.data.code === 0) {
          const label = res.data.data.chartList.map(item => item.name)
          const count = res.data.data.chartList.map(item => item.count)
          this.getCheckoutData(label, count)
        }
      })
    },
    // 获取结账数据
    getCheckoutData(label, count) {
      const chartDom = document.getElementById('checkout');
      const myChart = echarts.init(chartDom);
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        xAxis: {
          type: 'category',
          data: label,
          alignWithLabel: true,
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: count,
            type: 'bar',
            barWidth: "20%",
            itemStyle: {
                normal: {
                  color: () => {
                    return "#439BFF"
                  },
                },
            },
          },
        ],
        dataZoom: [
          {
            type: 'slider',
            show: 'true',
            start: 94,
            end: 100,
            handleSize: 8,
          },
          // {
          //   type: 'inside',
          //   start: 4,
          //   end: 10,
          // },
          // {
          //   type: 'slider',
          //   show: true,
          //   yAxisIndex: 0,
          //   filterMode: 'empty',
          //   width: 12,
          //   height: '100%',
          //   handleSize: 8,
          //   showDataShadow: false,
          //   left: '3%',
          // },
        ],
      };
      myChart.setOption(option);
    },
    // 接口获取业务营业额数据
    getTurnoveList() {
      this.turnoverTableData = []
      let beginTime = ''
      let endTime = ''
      if (this.turnoverDate && this.turnoverDate.length > 0) {
        beginTime = this.turnoverDate[0]
        endTime = this.turnoverDate[1]
      }
      this.$http.get(`${apis.businessMoneySum}?beginTime=${beginTime}&endTime=${endTime}`).then((res) => {
        if (res.data.code === 0) {
          const data = []
          const list = []
          const obj = {}
          list.push({
            label: '总数',
            props: 'total',
          })
          obj['total'] = res.data.data.extra.total
          res.data.data.chartList.forEach((item, index) => {
            data.push({
              value: item.money,
              name: item.name,
            })
            list.push({
              props: `label${index}`,
              label: item.name,
            })
            const label = `label${index}`
            obj[label] = item.money
          })
          this.getTurnoverData(data)
          this.$nextTick(() => {
            this.turnoverList = list
            this.turnoverTableData.push(obj)
          })
        }
      })
    },
    // 获取营业额数据
    getTurnoverData(list) {
      const chartDom = document.getElementById('turnover');
      const myChart = echarts.init(chartDom);
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)',
        },
        color: ['#3aa1ff', '#36cbcb', '#4ecb73', '#fbd437', '#f2637b', '#a97be9'],
        legend: {
          right: 'right',
          top: 'top',
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
          },
        },
        series: [
          {
            type: 'pie',
            radius: [0, 140],
            center: ['50%', '50%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 5,
            },
            data: list,
          },
        ],
      };
      myChart.setOption(option);
    },
    // 接口获取售卡数数据
    getSaleList() {
      this.saleTableData = []
      let beginTime = ''
      let endTime = ''
      if (this.saleDate && this.saleDate.length > 0) {
        beginTime = this.saleDate[0]
        endTime = this.saleDate[1]
      }
      this.$http.get(`${apis.memberCardSellCount}?beginTime=${beginTime}&endTime=${endTime}`).then((res) => {
        if (res.data.code === 0) {
          const data = []
          const list = []
          const obj = {}
          list.push({
            label: '总数',
            props: 'total',
          })
          obj['total'] = res.data.data.extra.total
          res.data.data.chartList.forEach((item, index) => {
            data.push({
              value: item.count,
              name: item.name,
            })
            list.push({
              props: `label${index}`,
              label: item.name,
            })
            const label = `label${index}`
            obj[label] = item.count
          })
          this.getSaleData(data)
          this.$nextTick(() => {
            this.saleList = list
            this.saleTableData.push(obj)
          })
        }
      })
    },
    // 售卡数
    getSaleData(list) {
      const chartDom = document.getElementById('sale');
      const myChart = echarts.init(chartDom);
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)',
        },
        color: ['#3aa1ff', '#e96c5b', '#f6c002'],
        legend: {
          right: 'right',
          top: 'top',
        },
        series: [
          {
            type: 'pie',
            radius: '80%',
            itemStyle: {
              borderRadius: 5,
            },
            data: list,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    // 接口获取购买金额数据
    getAmountList() {
      this.amountTableData = []
      let beginTime = ''
      let endTime = ''
      if (this.amountDate && this.amountDate.length > 0) {
        beginTime = this.amountDate[0]
        endTime = this.amountDate[1]
      }
      this.$http.get(`${apis.memberCardBuyMoney}?beginTime=${beginTime}&endTime=${endTime}`).then((res) => {
        if (res.data.code === 0) {
          const data = []
          const list = []
          const obj = {}
          list.push({
            label: '总数',
            props: 'total',
          })
          obj['total'] = res.data.data.extra.total
          res.data.data.chartList.forEach((item, index) => {
            data.push({
              value: item.money,
              name: item.name,
            })
            list.push({
              props: `label${index}`,
              label: item.name,
            })
            const label = `label${index}`
            obj[label] = item.money
          })
          this.getAmountData(data)
          this.$nextTick(() => {
            this.amountList = list
            this.amountTableData.push(obj)
          })
        }
      })
    },
    // 购买金额
    getAmountData(list) {
      const chartDom = document.getElementById('amount');
      const myChart = echarts.init(chartDom);
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)',
        },
        color: ['#3aa1ff', '#e96c5b', '#17eecb'],
        legend: {
          right: 'right',
          top: 'top',
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
          },
        },
        series: [
          {
            type: 'pie',
            radius: [40, 120],
            center: ['50%', '50%'],
            itemStyle: {
              borderRadius: 5,
            },
            data: list,
          },
        ],
      };
      myChart.setOption(option);
    },
    // 接口获取场馆收入分布数据
    getIncomeList() {
      let beginTime = ''
      let endTime = ''
      if (this.incomeDate && this.incomeDate.length > 0) {
        beginTime = this.incomeDate[0]
        endTime = this.incomeDate[1]
      }
      this.$http.get(`${apis.venueOrderMoneySum}?beginTime=${beginTime}&endTime=${endTime}`).then((res) => {
        if (res.data.code === 0) {
          const label = res.data.data.chartList.map(item => item.name)
          const count = res.data.data.chartList.map(item => item.money)
          this.getIncomeData(label, count)
        }
      })
    },
    // 获取场馆收入数据
    getIncomeData(label, count) {
      const chartDom = document.getElementById('income');
      const myChart = echarts.init(chartDom);
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        xAxis: {
          type: 'category',
          data: label,
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: count,
            type: 'bar',
            barWidth: "20%",
            itemStyle: {
                normal: {
                  color: () => {
                    return "#439BFF"
                  },
                },
            },
          },
        ],
        dataZoom: [
          {
            type: 'slider',
            show: 'true',
            start: 94,
            end: 100,
            handleSize: 8,
          },
        ],
      };
      myChart.setOption(option);
    },
    // 接口获取登记总人数数据
    getRegisterList() {
      let beginTime = ''
      let endTime = ''
      if (this.registerDate && this.registerDate.length > 0) {
        beginTime = this.registerDate[0]
        endTime = this.registerDate[1]
      }
      this.$http.get(`${apis.venueCheckInCount}?beginTime=${beginTime}&endTime=${endTime}`).then((res) => {
        if (res.data.code === 0) {
          const label = res.data.data.chartList.map(item => item.name)
          const count = res.data.data.chartList.map(item => item.count)
          this.getRegisterData(label, count)
        }
      })
    },
    // 获取登记人数数据
    getRegisterData(label, count) {
      const chartDom = document.getElementById('register');
      const myChart = echarts.init(chartDom);
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        xAxis: {
          type: 'category',
          data: label,
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: count,
            type: 'bar',
            barWidth: "20%",
            itemStyle: {
                normal: {
                  color: () => {
                    return "#439BFF"
                  },
                },
            },
          },
        ],
        dataZoom: [
          {
            type: 'slider',
            show: 'true',
            start: 94,
            end: 100,
            handleSize: 8,
          },
        ],
      };
      myChart.setOption(option);
    },
    // 接口获取客流量分布数据
    getPassengerFlowList() {
      let data = ''
      if (this.queryDate) {
        data = this.queryDate
      }
      this.$http.get(`${apis.findCheckInCountByHour}?queryDate=${data}`).then((res) => {
        if (res.data.code === 0) {
          const systemCount = res.data.data.map(item => item.systemCount)
          const checkGateCount = res.data.data.map(item => item.checkGateCount)
          const hour = res.data.data.map(item => `${item.vhour}时`)
          this.getPassengerFlowData(checkGateCount, systemCount, hour)
        }
      })
    },
    // 客流分布数据
    getPassengerFlowData(checkGateCount, systemCount, hour) {
      const chartDom = document.getElementById('passengerFlow');
      const myChart = echarts.init(chartDom);
      const option = {
        tooltip: {
          trigger: 'axis',
        },
        color: ['#f6c002', '#439BFF'],
        legend: {
          data: ['闸机核销', '系统核销'],
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: hour,
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: '闸机核销',
            type: 'line',
            data: checkGateCount,
          },
          {
            name: '系统核销',
            type: 'line',
            data: systemCount,
          },
        ],
      };
      myChart.setOption(option);
    },
  },
}
</script>
<style lang="less" scoped>
@import "../../assets/styles/mixin.scoped.less";
.cockpit {
  height: calc(100vh - 100px);
  width: 100%;
  overflow-y: auto;
  .content {
    box-sizing: border-box;
    width: 100%;
    padding: 0 25px;
    overflow: hidden;
    .box-card-2 {
      width: calc((100% - 29px) / 2);
      margin-right: 25px;
      margin-bottom: 25px;
      overflow: hidden;
      float: left;
      .clearfix {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .serchBox {
          display: flex;
          align-items: center;
        }
        .search-btn {
          min-width: 90px;
          height: 30px;
          line-height: 4px;
          color: #fff;
          background: #439BFF;
        }
      }
      .contant {
        .title {
          text-align: center;
          font-weight: bold;
        }
      }
    }
    .box-card-1 {
      width: calc(100% - 2px);
    }
  }
}
</style>
<style lang="less">
@import "../../assets/styles/mixin.less";
</style>
